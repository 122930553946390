import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getToken } from './store/users/UsersSelectors';
import { ROUTES } from './app-routes';
import { Login } from './components/login/Login';
import { Sidebar } from './components/Sidebar/Sidebar';
import { Header } from './components/header/header';
import './App.scss';
import { getOnLoginData } from './api/login.api';
import { LocalStorage } from './utils/LocalStorage.utils';
import { setCurrentUser, setLogo, setToken } from './store/users/UsersActions';
import { setCompanies } from './store/companies/companies.actions';
import { getCategoriesAPI } from './api/store.api';
import { StoreCategory } from './components/store/store.models';
import { setAllCategories } from './store/store/StoreActions';
import { useLocation } from 'react-router-dom';

export const App = ({ children }: any) => {
    const currentUser = useSelector(getCurrentUser);
    const token = useSelector(getToken);
    const storageToken = useMemo(() => {
        return LocalStorage.getItem('token');
    }, [LocalStorage.getItem('token')]);
    const dispatch = useDispatch();
    const location = useLocation();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current) {
            ref.current?.scrollTo(0, 0);
        }
    }, [location?.pathname]);

    useEffect(() => {
        // Reset session data when the pathname changes
        sessionStorage.removeItem('formData');
    }, [location?.pathname?.split('/')?.[0]]);

    useEffect(() => {
        getCategoriesAPI<StoreCategory>({ all: true }).then((res) => {
            dispatch(setAllCategories(res.data));
        });
    }, []);

    useEffect(() => {
        const localStorageToken = LocalStorage.getItem('token');
        if (localStorageToken) {
            dispatch(setToken(localStorageToken));
        }
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser && token) {
            getOnLoginData()
                .then((res) => {
                    if (
                        !res ||
                        !(res.currentUser?.userType === 'admin' || res.currentUser?.userType === 'HR')
                    ) {
                        dispatch(setToken(null));
                        return;
                    }

                    dispatch(setCurrentUser(res.currentUser));
                    dispatch(setCompanies(res.companies));
                    dispatch(setLogo(res.logo));
                })
                .catch(() => dispatch(setToken(null)));
        }
    }, [token]);

    // const sidebarItems = useMemo(() => {
    //     return currentUser
    //         ? ROUTES.filter((r) => r.roles?.includes(currentUser?.userType)).filter(
    //               (route) => route.description
    //           )
    //         : undefined;
    // }, [currentUser]);

    const sidebarItems = useMemo(() => {
        return ROUTES;
    }, [currentUser]);

    const renderAuthenticatedLayout = () => {
        if (!sidebarItems) return null;

        return (
            <div className="App">
                <Sidebar items={sidebarItems} />
                <div className={'content'} ref={ref}>
                    {children}
                </div>
            </div>
        );
    };

    return currentUser || token || storageToken ? renderAuthenticatedLayout() : <Login />;
    //return renderAuthenticatedLayout();
};
