import React from 'react';
import './ActiveUsersCard.scss';
import { ReactComponent as TwoPersons } from '../../assets/widget/TwoPersons.svg';

type ActiveUsersCardProps = {
    data: { totalVisits: number | undefined };
};

const ActiveUsersCard: React.FC<ActiveUsersCardProps> = ({ data }) => {
    return (
        <div className="active-users-card">
            <header className="header-ActiveUsersCard">
                <div className="title">
                    <h2>
                        משתמשים <br />
                        פעילים
                    </h2>
                    <p className="subtitle">ב30 הימים האחרונים</p>
                </div>
                <i className="icon">
                    <TwoPersons />
                </i>
            </header>

            <div className="user-stats">
                <h3 className="user-count">{data.totalVisits}</h3>
                <p className="growth">{/* 📈 <span className="green">12%</span> */}</p>
            </div>
        </div>
    );
};

export default ActiveUsersCard;
