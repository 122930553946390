import React from 'react';
import './ProductCard.scss'; // Import the SCSS file

interface ProductCardProps {
    imageUrl: string;
    discount: number | null;
    price: number;
    originalPrice?: number;
    isAvailable: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
    imageUrl,
    discount,
    price,
    originalPrice,
    isAvailable,
}) => {
    return (
        <div className="product-card-post">
            <div className="product-details">
                <div className="pricing">
                    <p className="current-price">{price.toFixed(2)}</p>
                    {originalPrice && <p className="original-price">{originalPrice.toFixed(2)}</p>}
                </div>
            </div>
            <img src={imageUrl} alt="Product" className="product-image" />
            {discount && <div className="discount-badge">-{discount}%</div>}
        </div>
    );
};

export default ProductCard;
