import React from 'react';
import './RelatedGroups.scss';
import { Group } from '../../models/groups.models';
import { getImageURL } from '../../utils/utils';
import { ReactComponent as GroupIcon } from '../../assets/groups/group-icon.svg';

interface RelatedGroupsProps {
    groups?: (Group | null)[];
}

const MAX_VISIBLE_GROUPS = 5;

export const RelatedGroups: React.FC<RelatedGroupsProps> = ({ groups }) => {
    if (!groups || groups.length === 0) {
        return null;
    }

    const visibleGroups = groups.slice(0, MAX_VISIBLE_GROUPS);
    const remainingCount = groups.length - MAX_VISIBLE_GROUPS;

    return (
        <div className="related-groups-container">
            <span className="label">התפרסם ב-</span>
            {visibleGroups.map((group) => (
                <div key={group?.groupID} className="related-group">
                    {group?.imageURL ? (
                        <div
                            className="image-container"
                            style={{ backgroundImage: `url(${getImageURL(group?.imageURL)})` }}
                        />
                    ) : (
                        <div className="image-container">
                            <GroupIcon />
                            <span className="acronym">
                                {group?.name
                                    ?.split(' ')
                                    .map((word) => word[0])
                                    .join('') || 'N/A'}
                            </span>
                        </div>
                    )}
                </div>
            ))}
            {remainingCount > 0 && <div className="related-group more-count">+{remainingCount}</div>}
        </div>
    );
};
