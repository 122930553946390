import { useController, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import './select.scss';
import { Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import { GroupBase, StylesConfig } from 'react-select';
import { customStyles } from './select';
import { useTranslation } from 'react-i18next';

export type AutoCompleteValue = {
    label: unknown;
    value: unknown;
};

type SmartAutoCompleteProps = {
    getLoadOptionsData: (inputValue: string) => Promise<AutoCompleteValue[]>;
    name: string;
    containerClass?: string;
    label: string;
    isDisabled?: boolean;
    placeholder?: string;
    isMulti?: boolean;
    isClearable?: boolean;
    onChange?: (value: string) => void;
    required?: boolean;
    keyValue?: string;
    onKeyDown?: (e: KeyboardEvent) => void;
    hideErrorMessage?: boolean; // New prop
};

export const SmartAutoComplete: FC<SmartAutoCompleteProps> = ({
    name,
    isDisabled,
    placeholder,
    getLoadOptionsData,
    containerClass,
    label,
    isMulti,
    onChange,
    required,
    keyValue,
    onKeyDown,
    isClearable = true,
    hideErrorMessage = false,
}) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control });

    const asyncCustomStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
        ...customStyles,
        control: (provided: any) => ({
            ...provided,
            borderRadius: '20px',
            borderColor: fieldState?.error ? 'red' : provided.borderColor,
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            alignItems: 'center',
        }),
        option: (provided: any, { isFocused, isSelected }) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            backgroundColor: isSelected ? 'var(--primary)' : 'none',
            ':hover': {
                backgroundColor: isFocused ? 'lightblue' : 'auto',
                color: 'var(--white)',
            },
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            justifyItems: 'start',
            maxHeight: '70px',
            padding: '4px 8px',
            fontSize: '12px',
            overflow: 'auto',
        }),
        menuPortal: (provided: any) => ({
            ...provided,
            zIndex: 3,
        }),
    };

    const loadOptions = (() => {
        let timerId: NodeJS.Timeout;

        const valuesLoadOptions = (inputValue: string, callback: (options: any[]) => void) => {
            clearTimeout(timerId);

            timerId = setTimeout(async () => {
                const results = await getLoadOptionsData(inputValue);
                if (!isMulti) {
                    return callback(results);
                }

                if (results) {
                    callback(results);
                }
            }, 300);
        };

        valuesLoadOptions.clear = () => {
            clearTimeout(timerId);
        };

        return valuesLoadOptions;
    })();

    return (
        <div className={containerClass}>
            {label && (
                <Label for={`${label}-input`}>
                    {label}
                    {required && <span className={'required'}>*</span>}
                </Label>
            )}
            <AsyncSelect
                key={keyValue}
                loadOptions={loadOptions}
                styles={asyncCustomStyles}
                value={field.value}
                className={classNames('react-select', { 'react-select-disabled': isDisabled })}
                onChange={(e: { value: string }) => {
                    onChange?.(e?.value ?? '');
                    field.onChange(e ?? '');
                }}
                onKeyDown={(e) => onKeyDown?.(e as unknown as KeyboardEvent)}
                filterOption={(option) => {
                    const selectedOptions = field.value as AutoCompleteValue[];
                    if (!selectedOptions || !Array.isArray(selectedOptions)) {
                        return true;
                    }

                    return !selectedOptions?.find((selected) => selected.label === option.label);
                }}
                menuPortalTarget={document.body}
                defaultOptions={true}
                cacheOptions={false}
                placeholder={placeholder}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isMulti={isMulti}
                menuPlacement="bottom"
                menuPosition={'fixed'}
                maxMenuHeight={200}
                controlShouldRenderValue={true}
                noOptionsMessage={({ inputValue }) => <CustomNoOptionsMessage inputValue={inputValue} />}
                loadingMessage={({ inputValue }) => <CustomLoadingMessage inputValue={inputValue} />}
            />
            <label className="error-label" style={{ display: hideErrorMessage ? 'none' : 'inline-block' }}>
                {/*@ts-ignore*/}
                {fieldState?.error?.message || fieldState?.error?.value?.message}
            </label>
        </div>
    );
};

export const CustomNoOptionsMessage = ({}: { inputValue: string }) => {
    const { t } = useTranslation();

    return <div>{t('noOptions')}</div>;
};

export const CustomLoadingMessage = ({}: { inputValue: string }) => {
    const { t } = useTranslation();

    return <div>{t('loading')}</div>;
};
