import { FilterForm } from '../table/Tables.types';

export const settingsTabs = ['סוגי וריאנטים', 'סטטוס הזמנה', 'סטטוס הזמנה פנימי'];

export type SettingsFilterForm = FilterForm & {
    list: string[];
};

export const defaultSettingsValues: SettingsFilterForm = {
    list: [],
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    eventsPage: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};
