import { timeAgo } from './post.utils';
import { boostGiftRedeemType, PostType } from '../home/home.models';
import { useTranslation } from 'react-i18next';
import { getImageURL } from '../../utils/utils';
import { Post as PostModel } from '../home/home.models';
import ProductCard from './ProductCard';

type PostHeaderProps = {
    post: PostModel;
};

export const PostHeader = ({ post }: PostHeaderProps) => {
    const { t } = useTranslation();

    return (
        <div className={'post-header'}>
            <div className={'publisher-details'}>
                <div className={'user-avatar'}>
                    <img
                        src={getImageURL(post.publisher?.publisherImageURL)}
                        alt={''}
                        className={'publisher-image'}
                    />
                    <div className={'publisher-and-role'}>
                        {post.type === 'gift' ? (
                            <h5 className={'publisher'}>{`${post.publisher?.publisherFullName} ${
                                post.publisher?.publisherPronoun === 'he' ? t('sentMale') : t('sentFemale')
                            } ${t(boostGiftRedeemType[post.type as 'gift' | 'giftRedeem'])} ל${
                                post.targetUserFullName
                            }`}</h5>
                        ) : post.type === 'giftRedeem' ? (
                            <h5 className={'publisher'}>{`${post.targetUserFullName} ${
                                post.publisher?.publisherPronoun === 'he'
                                    ? t('receivedMale')
                                    : t('receivedFemale')
                            } ${t('gift')} מ${post.publisher?.publisherFullName}`}</h5>
                        ) : (
                            <h5 className={'publisher'}>{post.publisher?.publisherFullName}</h5>
                        )}
                        <h6 className={'user-role'}>{post.publisher?.publisherRole}</h6>
                    </div>
                </div>
                <div className="post-text" dangerouslySetInnerHTML={{ __html: post.content }}></div>
            </div>
            <div className={'post-content'}>
                <div className={'post-content-wrapper'}>
                    <p className={'published-at'}>{timeAgo(post.timestamp)}</p>
                    {post.postAssetsURLs?.[0]?.url ? (
                        <div style={{ position: 'relative', width: '350px', height: '183.72px' }}>
                            <div
                                className={'image-container'}
                                style={{
                                    backgroundImage: `url(${getImageURL(post.postAssetsURLs[0].url)})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: '100% 100%',
                                    position: 'absolute',
                                    inset: '0',
                                    filter: 'blur(2px)',
                                }}></div>
                            <img
                                src={getImageURL(post.postAssetsURLs[0].url)}
                                alt="Post Asset"
                                className="post-image-cover"
                            />
                        </div>
                    ) : post?.productDetails ? (
                        <ProductCard
                            discount={null}
                            imageUrl={post.productDetails.productImageURL}
                            isAvailable={true}
                            price={post.productDetails.productOriginalPrice}
                            originalPrice={post.productDetails.productPrice}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
