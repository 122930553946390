import { useState, useEffect, useRef } from 'react';
import './widget.scss';
import BudgetSummary from './BudgetSummary';
import ProductList from './ProductList';
import BoostStatistics from './BoostStatistics';
import BudgetCard from './BudgetCard';
import ActiveUsersCard from './ActiveUsersCard';
import BudgetHistory from './BudgetHistory';
import PostsChart from './PostsChart';
import { ReactComponent as Heartbeat } from '../../assets/widget/HeartBit.svg';
import { ReactComponent as BoostIcon } from '../../assets/widget/BoostRocket.svg';
import { getWidgetsData } from '../../api/dashboard.api';
import { WidgetsData } from '../../api/api.models';

export const Widget = () => {
    const [widgetData, setWidgetData] = useState<WidgetsData | null>(null);
    const isFetched = useRef(false);

    useEffect(() => {
        if (!isFetched.current) {
            isFetched.current = true;
            getWidgetsData().then((res) => setWidgetData(res));
        }
    }, []);

    return (
        <div className="widgets">
            <div className="flex-widgets-wrapper">
                <div className="flex-widgets">
                    <ActiveUsersCard data={{ totalVisits: widgetData?.totalVisits }} />
                    <BoostStatistics
                        Icon={Heartbeat}
                        data={{
                            totalBoosts: widgetData?.totalBoosts,
                            totalPosts: widgetData?.totalPosts,
                            totalReplies: widgetData?.totalReplies,
                        }}
                        title="Activity"
                        subTitle="sumActivity"
                    />
                    <BudgetCard data={{ budget: widgetData?.budget, monthlyCost: widgetData?.monthlyCost }} />
                    <BoostStatistics
                        Icon={BoostIcon}
                        data={{
                            managerBoosts: widgetData?.managerBoosts,
                            nonManagerBoosts: widgetData?.nonManagerBoosts,
                        }}
                        title="Boosts"
                        subTitle="sumBoost"
                    />
                </div>
                <div className="flex-widgets">
                    <PostsChart data={{ posts: widgetData?.posts }} />
                    <BudgetHistory data={{ budget: widgetData?.budget }} />
                </div>
            </div>
            <div className="long-widgets">
                <BudgetSummary data={{ totalSocialCredits: widgetData?.totalSocialCredits }} />
                <ProductList data={widgetData?.productList} />
            </div>
        </div>
    );
};
