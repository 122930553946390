import { FilterForm } from '../table/Tables.types';
import { Group, GroupOption } from '../../models/groups.models';
import { User } from '../users/users.models';
import { EventsData } from '../../models/events.models';

export type BoostType = 'credit' | 'gift' | 'compliment';

export type Publisher = {
    publisherID: string;
    publisherFullName: string;
    publisherImageURL: string;
    publisherRole: string;
    publisherPronoun: 'he' | 'she';
};

export const boostGiftRedeemType = {
    gift: 'gift',
    giftRedeem: 'giftRedeem',
};

export type PostType =
    | 'text'
    | 'image'
    | 'video'
    | 'gift'
    | 'giftGroup'
    | 'spot'
    | 'boost'
    | 'join'
    | 'leave'
    | 'boostRedeem'
    | 'giftRedeem';

export type BasePost = {
    companyID?: string;
    postID: string;
    targetUserID?: string;
    type: PostType;
    behalfOfUserID: string;
    content: string;
    campaign?: string;
    hashTags: string[];
    toBePublishedAt?: number;
    relatedGroupsIDs: string[];
    relatedUsersIDs: string[];
    postAssetsURLs?: PostAsset[];
};

export type Gift = BasePost & {
    companyID?: string;
    postID: string;
    targetUserID?: string;
    type: 'gift';
    content: string;
    campaign?: string;
    toBePublishedAt?: number;
    relatedGroupsIDs: string[];
    relatedUsersIDs: string[];
    productID?: string;
    creditsAmount?: number;
    isEvent?: boolean;
    eventName?: string;
};

export type Post = {
    postID: string;
    companyID: string;
    publisher: Publisher;
    campaign?: string;
    targetUserID?: string;
    targetUserFullName?: string;
    targetUserImageURL?: string;
    type: PostType;
    views: number;
    content: string;
    timestamp: number;
    lastUpdatedAtTimestamp: number;
    toBePublishedAt?: number;
    relatedGroupsIDs: string[];
    presentedGroup: PresentedGroup;
    likes: number;
    repliesAmount: number;
    hiddenFromUsersID: string[];
    hashTags: string[];
    postAssetsURLs?: PostAsset[];
    boostType?: BoostType;
    complimentID?: string;
    relatedGroups?: Group[];
    giftDetails?: GiftDetails;
    isStyled?: boolean;
    isBase64?: boolean;
    eventID?: string;
    readByUsers: string[];
    productDetails: {
        productID: string;
        productImageURL: string;
        productName: string;
        productOriginalPrice: number;
        productPrice: number;
    };
    replies?: Replies[];
};

export type PostAsset = {
    url: string;
    height?: number;
    width?: number;
};

export type PresentedGroup = {
    groupID: string;
    groupName: string;
    groupImage: string;
};

export type GiftDetails = {
    giftDetailsID: string;
    creditsAmount: number | undefined;
    productDetails: ProductDetails | undefined;
    isRedeemed: boolean;
    isCancelled?: boolean;
    cancelledAt?: number;
    boostType: BoostType | undefined;
    redeemedType?: BoostType;
    timestamp: number;
    redeemedAt?: number;
    postID: string;
    fromUserID?: string;
    fromUser?: User;
    toUserID?: string;
    toUser?: User;
};

export type ProductDetails = {
    productID: string;
    productName: string;
    productPrice: number;
    productImageURL: string;
};

export type PostCreate = {};

export type HomeForm = FilterForm<Post> & {
    posts: Post[];
    events: EventsData[];
    company?: { label: string; value: string } | null;
    group?: GroupOption | null;
    user?: { label: string; value: string } | null;
    campaign?: string;
    fromTimestamp?: number;
    toTimestamp?: number;
    futurePostsOnly: boolean;
};

export const homeFormInitialValues: HomeForm = {
    posts: [],
    events: [],
    company: null,
    group: null,
    user: null,
    campaign: '',
    fromTimestamp: undefined,
    toTimestamp: undefined,
    futurePostsOnly: false,
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    eventsPage: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: 'timestamp',
    },
};

export type Replies = {
    publisherID: string;
    publisherImageURL?: string;
    publisherFullName: string;
    publisherRole: string;
    content: string;
    isUserLiked: boolean;
    timestamp: number;
    iLiked: boolean;
};

export type Like = { userID: string };
