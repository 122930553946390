import React from 'react';
import './BudgetSummary.scss';
import { ReactComponent as CreditCard } from '../../assets/widget/CreditCard.svg';

type BudgetSummaryProps = {
    data: { totalSocialCredits: number | undefined };
};

const BudgetSummary: React.FC<BudgetSummaryProps> = ({ data }) => {
    return (
        <div className="budget-summary">
            <header className="header-BudgetSummary">
                <h2 className="title">תקציב הוקרה שחולק</h2>
                <CreditCard />
            </header>

            <div className="section">
                <h3 className="amount green">{data.totalSocialCredits} ₪</h3>
                <p className="label">סה״כ יתרת תקציבי הוקרה</p>
            </div>

            <hr />

            <div className="section">
                <h3 className="amount">0 ₪</h3>
                <p className="label">לא חולקו</p>
            </div>

            <hr />

            <div className="section">
                <h3 className="amount blue">0 מנהלים</h3>
                <p className="label">הגיעו לתקרת תקציב ההוקרה</p>
            </div>
        </div>
    );
};

export default BudgetSummary;
