//import { Post } from '../components/home/home.models';
//import { DashboardMetrics } from '../components/dashboard/dashboard.models';
import { API } from './api';
import { GetInstances, GetResponse, WidgetsData } from './api.models';
//import { BoostsAndGiftsReport, Transaction } from '../components/reports/reports.models';

export const getDashboardMetrics = async (getBy: any): Promise<GetResponse & { data: any }> => {
    return API.get({ url: `dashboard/metrics`, params: getBy });
};

export const getWidgetsData = async (params?: Record<string, any>): Promise<WidgetsData> => {
    return API.get({ url: 'dashboard/widgets', params });
};
