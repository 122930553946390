import './WhoLikedViewed.scss';
import { Heart, MessageSquare } from 'react-feather';
import { Replies } from '../home/home.models';
import { getImageURL } from '../../utils/utils';

type WhoLikedViewedProps = {
    likesCount: number;
    viewsCount: number;
    users: Replies[];
};

export const WhoLikedViewed = ({ likesCount, viewsCount, users }: WhoLikedViewedProps) => {
    return (
        <div className="who-liked-viewed">
            <div className="header-WhoLikedViewed">
                <span>
                    מי ראה ({viewsCount}) ❤️ ומי אהב ({likesCount})
                </span>
            </div>

            <div className="user-list">
                {users.length > 0 ? (
                    users.map((user, index) => (
                        <div key={index} className="user-item">
                            <img
                                src={getImageURL(user.publisherImageURL)}
                                alt={user.publisherFullName}
                                className="profile-img"
                            />
                            <div className="user-info">
                                <strong>{user.publisherFullName}</strong>
                                <p>{user.publisherRole}</p>
                            </div>
                            <div className="icons">
                                <MessageSquare className="comment-icon" />
                                <Heart className="heart-icon" />
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-users">אין מידע להצגה</p>
                )}
            </div>
        </div>
    );
};
