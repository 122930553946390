import './event.scss';
import { EventsData } from '../../models/events.models';
import { getImageURL } from '../../utils/utils';
import { calcDates, checkEventSvgByName } from './eventsUtil';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TelegramIcon } from '../../assets/events/telegram.svg';
import { ReactComponent as PresentIcon } from '../../assets/events/present.svg';
import { ReactComponent as NoAction } from '../../assets/events/NoAction.svg';
import { ReactComponent as Astronaut } from '../../assets/events/Astronaut.svg';
import React, { useState } from 'react';

type EventProps = {
    event: EventsData;
};

export const Event = ({ event }: EventProps) => {
    const { t } = useTranslation();
    const { formattedDate, timeLeftMessage } = calcDates(event.timestamp);
    const svg = checkEventSvgByName(event.eventType);

    const eventTime = event.timestamp < 1e10 ? event.timestamp * 1000 : event.timestamp;
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    if (eventTime < startOfDay.getTime()) {
        return null;
    }

    const [selectedAction, setSelectedAction] = useState<string | null>(null);
    const [isActivated, setIsActivated] = useState(false);

    const handleButtonClick = (action: string) => {
        setSelectedAction(action);
        setIsActivated(true);
    };

    return (
        <div className="event-container">
            <div className={`event-wrapper ${isActivated ? 'event-activated' : ''}`}>
                <div className="event-icon">{svg && React.createElement(svg, { className: 'svg-icon' })}</div>

                <div className="event-profile-details">
                    <div className="event-profile-picture-wrapper">
                        {event.userImageURL ? (
                            <img
                                src={getImageURL(event.userImageURL)}
                                alt=""
                                className="event-profile-picture"
                            />
                        ) : (
                            <Astronaut fill="#8049FE" />
                        )}
                    </div>
                    <div className="event-name-role">
                        <h5 className="event-profile-name">{event.userFullName}</h5>
                        <h6 className="event-profile-role">{event.role}</h6>
                    </div>
                </div>

                <div className="event-details">
                    <div className="event-details-wrapper">
                        <div className="event-date-type">
                            <p>
                                <span className="event-name">{event.name}</span>
                                <span className="dot">.</span>
                                <span className="formatted-date">{formattedDate}</span>
                            </p>
                            <p className="days-left">{timeLeftMessage}</p>
                        </div>
                    </div>
                    <div className="event-details-buttons">
                        <button
                            className={`event-button ${selectedAction === 'no-action' ? 'selected' : ''} ${
                                isActivated ? 'icon-only' : ''
                            }`}
                            onClick={() => handleButtonClick('no-action')}>
                            <span className="button-text">{t('WithoutAction')}</span>
                            <NoAction width="20" height="20" />
                        </button>
                        <button
                            className={`event-button ${selectedAction === 'telegram' ? 'selected' : ''} ${
                                isActivated ? 'icon-only' : ''
                            }`}
                            onClick={() => handleButtonClick('telegram')}>
                            <span className="button-text">{t('BrahaOrder')}</span>
                            <TelegramIcon width="20" height="20" />
                        </button>
                        <button
                            className={`event-button ${selectedAction === 'gift' ? 'selected' : ''} ${
                                isActivated ? 'icon-only' : ''
                            }`}
                            onClick={() => handleButtonClick('gift')}>
                            <span className="button-text">{t('GifrOrder')}</span>
                            <PresentIcon width="20" height="20" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
