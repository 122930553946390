import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './BudgetHistory.scss';
import { ReactComponent as Telegram } from '../../assets/widget/Telegram.svg';

const secondaryColor = '#D6CFF1';
type BudgetHistoryProps = {
    data: {
        budget: number | undefined;
    };
};

const BudgetHistory: React.FC<BudgetHistoryProps> = ({ data }) => {
    const chartData = [
        { month: 'פברואר', value: data.budget },
        { month: 'פברואר', value: data.budget },
    ];
    return (
        <div className="budget-history">
            <header className="header-BudgetHistory">
                <h2 className="title">היסטורית תקציב סל</h2>
                <i className="icon">
                    <Telegram />
                </i>
            </header>

            <ResponsiveContainer width="100%" height={140}>
                <AreaChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" padding={{ left: 20, right: 20 }} />
                    <YAxis hide />
                    <Tooltip content={<CustomTooltip />} />
                    <Area type="linear" dataKey="value" stroke="none" fill={secondaryColor} fillOpacity={1} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

// Custom Tooltip
const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length && payload[0].value) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-value">{`${payload[0].value.toLocaleString()} ₪`}</p>
            </div>
        );
    }
    return null;
};

export default BudgetHistory;
