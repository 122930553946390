import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../input-and-checkbox/input.scss';
import { Calendar } from 'react-feather';
import he from 'date-fns/locale/he';
import { Label } from 'reactstrap';
registerLocale('he', he);

interface DatePickerProps {
    dateFormat?: string;
    showYearPicker?: boolean;
    showYearDropdown?: boolean;
    name: string;
    label?: string;
    containerClass?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    futureOnly?: boolean;
    onlyMonthHeader?: boolean;
    required?: boolean;
    onKeyDown?: (e: KeyboardEvent) => void;
}

export const SmartDatePicker: FC<DatePickerProps> = ({
    dateFormat,
    showYearPicker,
    showYearDropdown = true,
    label,
    placeholder,
    containerClass,
    className,
    disabled,
    futureOnly,
    name,
    onKeyDown,
    onlyMonthHeader,
    required,
}) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control });

    return (
        <div className={`custom-datepicker ${containerClass}`}>
            {label && (
                <Label for={`${label}-input`}>
                    {label}
                    {required && <span className={'required'}>*</span>}
                </Label>
            )}
            <div className={`date-with-icon`}>
                <Calendar className="icon" width={16} height={16} />
                <div className={'input-icon-separator'} />
                <DatePicker
                    className={`${className} ${fieldState?.error && 'error-msg'} date-input datepicker-input`}
                    selected={field.value}
                    calendarStartDay={0}
                    placeholderText={placeholder}
                    dateFormat={dateFormat}
                    showYearPicker={showYearPicker}
                    showYearDropdown={showYearDropdown}
                    dropdownMode="select"
                    showIcon={true}
                    renderCustomHeader={onlyMonthHeader ? CustomOnlyMonthHeader : undefined}
                    onChange={field.onChange}
                    isClearable
                    locale={'he'}
                    adjustDateOnChange
                    disabled={disabled}
                    portalId="root-portal"
                    strictParsing={true}
                    maxDate={dateFormat !== 'dd/MM' ? new Date() : null}
                    minDate={futureOnly ? new Date() : null}
                    onKeyDown={(e) => onKeyDown?.(e as unknown as KeyboardEvent)}
                    popperClassName="react-datepicker-popper"
                />
            </div>
            {<label className="error-label">{fieldState.error?.message}</label>}
        </div>
    );
};

type CustomOnlyMonthHeaderProps = {
    monthDate: Date;
    customHeaderCount: number;
    decreaseMonth: () => void;
    increaseMonth: () => void;
};

const CustomOnlyMonthHeader = ({ monthDate, increaseMonth, decreaseMonth }: CustomOnlyMonthHeaderProps) => {
    return (
        <div>
            <button
                className={'react-datepicker__navigation react-datepicker__navigation--previous'}
                onClick={decreaseMonth}>
                <span
                    className={
                        'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                    }>
                    {'<'}
                </span>
            </button>
            <span className="react-datepicker__current-month">
                {monthDate.toLocaleString('he-IL', {
                    month: 'long',
                })}
            </span>
            <button
                className={'react-datepicker__navigation react-datepicker__navigation--next'}
                onClick={increaseMonth}>
                <span className={'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'}>
                    {'>'}
                </span>
            </button>
        </div>
    );
};
