import React from 'react';
import './ProductList.scss';
import { ReactComponent as ShoppingBag } from '../../assets/widget/ShoppingBag.svg';
import { ProductListItem } from '../../api/api.models';
import { getImageURL } from '../../utils/utils';

interface ProductListProps {
    data?: ProductListItem[];
}

const ProductCard: React.FC<{ product: ProductListItem }> = ({ product }) => {
    return (
        <div className="product-card">
            <img src={getImageURL(product.mainImage)} alt={product.productName} className="product-image" />
            <div className="product-info">
                <h3 className="product-title">{product.productName}</h3>
                <div className="product-stats">
                    <span className="purchases">רכישות {product.quantity}</span>
                </div>
            </div>
        </div>
    );
};

const ProductList: React.FC<ProductListProps> = ({ data = [] }) => {
    return (
        <div className="product-list">
            <header className="header-ProductCard">
                <h2 className="title">
                    <span>מוצרים מובילים</span>
                </h2>
                <ShoppingBag width={18} height={20} />
            </header>
            {data.length > 0 ? (
                data.map((product, index) => <ProductCard key={index} product={product} />)
            ) : (
                <p className="no-products">אין מוצרים להצגה</p>
            )}
        </div>
    );
};

export default ProductList;
