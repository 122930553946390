import { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/posts/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/posts/filter.svg';
import { ReactComponent as CalIcon } from '../../assets/posts/calenderDown.svg';
import { ReactComponent as TelegIcon } from '../../assets/posts/telegramPlus.svg';
import { useTranslation } from 'react-i18next';
import { Filter, FiltersModels } from '../filters/filters.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';
import { Button } from 'reactstrap';
import { SmartAutoComplete } from '../form/select/auto-complete';

type PostFilterButtonsProps = {
    onPost: () => void;
    filters: Filter[];
    clearFilters: () => void;
};

export const PostFilterButtons = ({ onPost, filters, clearFilters }: PostFilterButtonsProps) => {
    const { t } = useTranslation();
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const buttonFilter = filters.find(
        (filter): filter is Filter & { onClick: () => void; icon?: JSX.Element; label: string } =>
            filter.type === FiltersModels.BUTTON
    );

    const dateFilters = filters.filter(
        (
            filter
        ): filter is Filter & { onKeyDown?: (e: KeyboardEvent) => void; name: string; label: string } =>
            filter.type === FiltersModels.DATE
    );

    return (
        <div className="buttons-container">
            <p className={'postsTitle'}>{t('posts')}</p>
            <div className="buttons-wrapper">
                <div className="search-bar">
                    {/* <SearchIcon className="search-icon" /> */}
                    {/* <input type="text" placeholder="חיפוש פוסט..." /> */}
                    <FilterIcon
                        className="search-icon filter-icon"
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                    />
                </div>

                <button
                    className="btn secondary"
                    onClick={() => {
                        const scrollableDiv = document.getElementById('scrollableDiv');
                        if (scrollableDiv) {
                            scrollableDiv.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls InfiniteScroll container to top
                        }
                    }}>
                    עכשיו
                    <CalIcon />
                </button>

                <button onClick={onPost} className="btn primary">
                    פוסט חדש
                    <TelegIcon />
                </button>
            </div>

            {isFilterOpen && (
                <div className="filter-modal-overlay" onClick={() => setIsFilterOpen(false)}>
                    <div
                        className={`filter-dropdown ${isFilterOpen ? 'open' : 'close'}`}
                        onClick={(e) => e.stopPropagation()}>
                        <div className="right-column">
                            <div className="filter-options">
                                {filters.map((filter, index) => {
                                    return filter.type === FiltersModels.INPUT ? (
                                        <div key={index}>
                                            <SmartInput
                                                name={filter.name}
                                                label={filter.label}
                                                placeholder={filter.placeholder}
                                                onKeyDown={filter.onKeyDown}
                                            />
                                        </div>
                                    ) : filter.type === FiltersModels.AUTO_COMPLETE ? (
                                        <div key={index}>
                                            <SmartAutoComplete
                                                key={index}
                                                getLoadOptionsData={filter.getLoadOptionsData}
                                                isClearable={true}
                                                name={filter.name}
                                                isDisabled={filter.isDisabled}
                                                placeholder={filter.placeholder}
                                                isMulti={filter.isMulti}
                                                label={filter.label}
                                                onKeyDown={filter.onKeyDown}
                                                hideErrorMessage={true}
                                            />
                                        </div>
                                    ) : filter.type === FiltersModels.CHECK_BOX ? (
                                        <div
                                            key={index}
                                            style={{ display: 'flex' }}
                                            className={'checkbox-with-label'}>
                                            <SmartInput key={index} name={filter.name} type={'checkbox'} />
                                            <span className={'checkbox-label'}>{filter.label}</span>
                                        </div>
                                    ) : (
                                        <div key={index}></div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="left-column">
                            <div className="filter-section">
                                <label>טווח תאריכים</label>
                                <div className="date-range">
                                    {dateFilters.map((dateFilter, index) => (
                                        <SmartDatePicker
                                            key={index}
                                            name={dateFilter.name}
                                            label={dateFilter.label}
                                            placeholder={'DD/MM/YYYY'}
                                            dateFormat={'dd/MM/yyyy'}
                                            onKeyDown={dateFilter.onKeyDown}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="filter-buttons">
                            <button onClick={clearFilters} className="btn secondary">
                                ניקוי מסננים
                            </button>
                            {buttonFilter && (
                                <Button
                                    className={`btn primary `}
                                    onClick={buttonFilter.onClick}
                                    theme="primary"
                                    icon={buttonFilter.icon}>
                                    {buttonFilter.label}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
