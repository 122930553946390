import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import './BoostStatistics.scss';
import { useTranslation } from 'react-i18next';

type BoostStatisticsProps = {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    data:
        | { totalBoosts?: number; totalPosts?: number; totalReplies?: number }
        | { managerBoosts?: number; nonManagerBoosts?: number };
    title: string;
    subTitle: string;
};

const isTotalBoostsData = (
    data: BoostStatisticsProps['data']
): data is { totalBoosts?: number; totalPosts?: number; totalReplies?: number } => {
    return 'totalBoosts' in data;
};

const BoostStatistics: React.FC<BoostStatisticsProps> = ({ Icon, data, title, subTitle }) => {
    const { t } = useTranslation();

    const LABELS: Record<string, string> = {
        totalBoosts: t('totalBoosts'),
        totalPosts: t('totalPosts'),
        totalReplies: t('totalReplies'),
        managerBoosts: t('managerBoosts'),
        nonManagerBoosts: t('nonManagerBoosts'),
    };

    const chartData = Object.entries(data).map(([key, value]) => ({
        name: LABELS[key] || key,
        value: value ?? 0,
    }));

    const totalSum = isTotalBoostsData(data)
        ? (data.totalBoosts ?? 0) + (data.totalPosts ?? 0) + (data.totalReplies ?? 0)
        : (data.managerBoosts ?? 0) + (data.nonManagerBoosts ?? 0);

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip-Statistics">
                    <p className="tooltip-label">
                        {payload[0].name}: <strong>{payload[0].value}</strong>
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="boost-statistics">
            <header className="header-BoostStatistics">
                <h2 className="title">
                    <span>{t(`${title}`)}</span>
                </h2>
                <Icon className="icon" />
            </header>

            <div className="chart-container">
                <PieChart width={140} height={140}>
                    <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={42.5}
                        outerRadius={62.5}
                        startAngle={180}
                        endAngle={-180}
                        activeShape={{ fill: '#4833a0' }}
                        dataKey="value">
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={'#dad6ec'} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>

                <div className="chart-center">
                    <h3 className="big-text">{totalSum}</h3>
                    <span className="sub-text">{t(`${subTitle}`)}</span>
                    {/* <p className="increase">
                        <span>📈 16%</span>
                    </p> */}
                </div>
            </div>
        </div>
    );
};

export default BoostStatistics;
