import './eventFilterButtons.scss';
import { ReactComponent as CalIcon } from '../../assets/posts/calenderDown.svg';
import { ReactComponent as PresentPlus } from '../../assets/posts/presentPlus.svg';
import { useTranslation } from 'react-i18next';

type EventFilterButtonsProps = {
    onGift: () => void;
};

export const EventFilterButtons = ({ onGift }: EventFilterButtonsProps) => {
    const { t } = useTranslation();

    return (
        <div className="filter-wrapper">
            <p className={'eventsTitle'}>{t('upcomingEvents')}</p>
            <div className="buttons-wrapper">
                <button
                    className="btn secondary"
                    onClick={() => {
                        const scrollableDiv = document.getElementById('eventsWheel');
                        if (scrollableDiv) {
                            scrollableDiv.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls InfiniteScroll container to top
                        }
                    }}>
                    עכשיו
                    <CalIcon />
                </button>

                <button onClick={onGift} className="btn primary">
                    מתנה חדשה
                    <PresentPlus />
                </button>
            </div>
        </div>
    );
};
