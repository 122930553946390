import './PublishedInGroups.scss';
import { getImageURL } from '../../utils/utils';
import { Group } from '../../models/groups.models';

type PublishedInGroupsProps = {
    groups: Group[];
};

export const PublishedInGroups = ({ groups }: PublishedInGroupsProps) => {
    const getGroupLabel = (type: string): string => {
        const labels: { [key: string]: string } = {
            public: 'קבוצה ציבורית',
            private: 'שיחה פרטית',
            company: 'קבוצה ארגונית',
            invitation: 'קבוצה למוזמנים',
            HR: 'קבוצת HR',
            core: 'קבוצה מנוהלת',
        };

        return labels[type] || 'סוג לא מוכר'; // Default case if type is unknown
    };
    return (
        <div className="published-in-groups">
            <div className="header-PublishedInGroups">
                <span>איפה התפרסם? ({groups.length})</span>
            </div>

            <div className="groups-list">
                {groups.length > 0 ? (
                    groups.map((group, index) => (
                        <div key={index} className="group-item">
                            <img src={getImageURL(group.imageURL)} alt={group.type} className="group-img" />
                            <div className="group-details">
                                <span className="group-name">{group.name}</span>
                                <span className="group-name">{getGroupLabel(group.type)}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-groups">אין קבוצות</p>
                )}
            </div>
        </div>
    );
};
