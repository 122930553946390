import { PossibleUserTypes } from '../components/users/users.models';
import { Order } from '../hooks/useSort';
import { GroupType } from '../models/groups.models';
import { TransactionType } from '../models/shared.models';
import { PostType } from '../components/home/home.models';

export const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'access-control-allow-origin': '*',
};

export type METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type Request = {
    url: string;
    method?: METHOD;
    body?: any;
    headers?: Record<string, string>;
    params?: Record<string, unknown>;
};

export type GetRequest = Omit<Request, 'body' | 'method'>;
export type PostRequest = Omit<Request, 'method'>;
export type PutRequest = Omit<Request, 'method'>;
export type PatchRequest = Omit<Request, 'method'>;
export type DeleteRequest = Omit<Request, 'body' | 'method'>;

export type GetInstances<T> = {
    isExtended?: boolean;
    productID?: string;
    companyID?: string;
    companiesIDs?: string[];
    postType?: PostType;
    categoryIDs?: string[];
    restrictPrivate?: boolean;
    filterVariants?: boolean;
    supplierIDs?: string[];
    includeNotifications?: boolean;
    availabilitySupplier?: boolean;
    variantSearch?: string;
    isShown?: boolean;
    shownByCompany?: boolean;
    excludeID?: string;
    productName?: string;
    purchaseStatus?: string[];
    internalPurchaseStatus?: string[];
    fromTimestamp?: number;
    toTimestamp?: number;
    userSpecialFields?: string;
    companySpecialFields?: string;
    includeFuturePosts?: boolean;
    futurePostsOnly?: boolean;
    campaign?: string | null;
    supplierID?: string;
    parentCategoryID?: string;
    childrenCategoryID?: string;
    purchaseID?: string;
    catalogNumberID?: string;
    groupID?: string;
    groupsIDs?: string[];
    postID?: string;
    groupType?: GroupType;
    hotSale?: boolean;
    transactionType?: TransactionType;
    accountID?: string;
    accountIDs?: string[];
    name?: string;
    extraFilters?: { userType?: PossibleUserTypes; search?: string };
    userID?: string;
    boostByOrToUserID?: string;
    phone?: string;
    all?: boolean;
    offset?: number;
    count?: number;
    sortBy?: Order;
    sortField?: keyof T;
};

export type GetResponse = {
    offset: number;
    count: number;
    total: number;
    found: number;
};

export type ProductListItem = {
    mainImage: string;
    productName: string;
    quantity: number;
};

export type WidgetsData = {
    productList: ProductListItem[];
    budget: number;
    managerBoosts: number;
    monthlyCost: number;
    nonManagerBoosts: number;
    totalBoosts: number;
    totalPosts: number;
    totalReplies: number;
    totalSocialCredits: number;
    totalVisits: number;
    posts: { name: string; count: number }[];
};
