import { useState } from 'react';
import Modal from 'react-modal';
import './post.scss';
import { Post as PostModel } from '../home/home.models';
import { PostHeader } from './PostHeader';
import { Heart, MessageSquare, MoreVertical, Calendar, X } from 'react-feather';
import { DropdownButton } from '../form/buttons/DropdownButton';
import { RelatedGroups } from './RelatedGroups';
import { getImageURL } from '../../utils/utils';
import { WhoLikedViewed } from './WhoLikedViewed';
import { WhoReplied } from './WhoReplied';
import { PublishedInGroups } from './PublishedInGroups';

type PostProps = {
    post: PostModel;
    deletePost: () => void;
};

export const Post = ({ post, deletePost }: PostProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className={'post-container'} onClick={() => setIsModalOpen(true)}>
                <div className={'post-wrapper'}>
                    <PostHeader post={post} />
                    <div className={'post-content'}>
                        <div className={'post-details-and-settings'}>
                            <RelatedGroups groups={post?.relatedGroups} />
                            <div className={'post-buttons'}>
                                <span className={'post-detail-big-gap'}>{post.eventID && <Calendar />}</span>
                                <span className={'post-detail'}>
                                    {post.repliesAmount}
                                    <MessageSquare />
                                </span>
                                <span className={'post-detail'}>
                                    {post.likes}
                                    <Heart />
                                </span>
                                <span className={'post-detail detail-cursor'}>
                                    <DropdownButton
                                        options={[{ label: 'מחק פוסט', onClick: deletePost }]}
                                        buttonLabel={<MoreVertical />}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                className="post-modal"
                overlayClassName="post-modal-overlay">
                <button className="close-button" onClick={() => setIsModalOpen(false)}>
                    <X />
                </button>

                <div className={'post-container'}>
                    <div className={'post-wrapper'}>
                        <PostHeader post={post} />
                    </div>
                </div>
                <div className="bottom-post">
                    <WhoLikedViewed
                        likesCount={post.likes}
                        viewsCount={post.readByUsers.length}
                        users={post.replies ?? []}
                    />
                    <WhoReplied repliesCount={post.repliesAmount} replies={post.replies ?? []} />
                    <PublishedInGroups groups={post.relatedGroups ?? []} />
                </div>
            </Modal>
        </>
    );
};
