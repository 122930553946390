import './WhoReplied.scss';
import { Replies } from '../home/home.models';
import { getImageURL } from '../../utils/utils';

type WhoRepliedProps = {
    repliesCount: number;
    replies: Replies[];
};

const formatTimestamp = (timestamp: number): string => {
    const now = Date.now();
    const diffMs = now - timestamp;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'היום';
    return `לפני ${diffDays} ימים`;
};

export const WhoReplied = ({ repliesCount, replies }: WhoRepliedProps) => {
    return (
        <div className="who-replied">
            <div className="header-WhoReplied">
                <span>מי הגיב ({repliesCount})</span>
            </div>

            <div className="replies-list">
                {replies.length > 0 ? (
                    replies.map((reply, index) => (
                        <div key={index} className="reply-item">
                            <img
                                src={getImageURL(reply.publisherImageURL)}
                                alt={reply.publisherFullName}
                                className="profile-img"
                            />

                            <div className="reply-info">
                                <div className="user-details">
                                    <strong className="user-name">{reply.publisherFullName}</strong>
                                    <span className="separator"> / </span>
                                    <span className="user-role">{reply.publisherRole}</span>
                                    <span className="timestamp"> • {formatTimestamp(reply.timestamp)}</span>
                                </div>
                                <p className="reply-text">{reply.content}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-replies">אין תגובות</p>
                )}
            </div>
        </div>
    );
};
