import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './PostsChart.scss';
import { ReactComponent as Clock } from '../../assets/widget/Clock.svg';
import { useTranslation } from 'react-i18next';

const secondaryColor = '#D6CFF1';

type PostsChartProps = {
    data: { posts: { name: string; count: number }[] | undefined };
};

const PostsChart: React.FC<PostsChartProps> = ({ data }) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const { t } = useTranslation();

    const translatedData =
        data.posts?.map((post) => ({
            name: t(post.name),
            count: post.count,
        })) || [];

    return (
        <div className="posts-chart">
            <header className="header-PostsChart">
                <div className="title-container">
                    <h2 className="title">{t('posts')}</h2>
                    <p className="subtitle">{t('last_30_days')}</p>
                </div>
                <i className="icon">
                    <Clock />
                </i>
            </header>

            <ResponsiveContainer width="100%" height={130}>
                <BarChart
                    data={translatedData}
                    onMouseMove={(e) => {
                        if (e.activeTooltipIndex !== undefined) {
                            setHoveredIndex(e.activeTooltipIndex);
                        }
                    }}
                    onMouseLeave={() => setHoveredIndex(null)}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" axisLine={false} tickLine={false} />
                    <YAxis hide />
                    <Tooltip content={<CustomTooltip t={t} />} />

                    <Bar
                        dataKey="count"
                        radius={[5, 5, 0, 0]}
                        fill={secondaryColor}
                        onMouseOver={(e, index) => setHoveredIndex(index)}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

// Custom Tooltip
const CustomTooltip = ({ active, payload, t }: any) => {
    if (active && payload && payload.length && payload[0].value) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-value">{`${payload[0].value} ${t('posts')}`}</p>
            </div>
        );
    }
    return null;
};

export default PostsChart;
