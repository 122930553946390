import React from 'react';
import './BudgetCard.scss';
import { ReactComponent as CreditCard } from '../../assets/widget/CreditCard.svg';

type BudgetCardProps = {
    data: {
        budget: number | undefined;
        monthlyCost: number | undefined;
    };
};

const BudgetCard: React.FC<BudgetCardProps> = ({ data }) => {
    return (
        <div className="budget-card">
            <header className="header-BudgetCard">
                <div className="title">
                    <h2>תקציב סל - חודש פברואר</h2>
                    <p className="subtitle">עלות חודשית צפויה</p>
                </div>
                <i className="icon">
                    <CreditCard />
                </i>
            </header>

            <div className="budget-content">
                <div className="budget-item">
                    <h3 className="amount green">₪ {data.budget}</h3>
                    <p className="label">תקציב סל</p>
                </div>

                <div className="divider"></div>

                <div className="budget-item">
                    <h3 className="amount red">₪ {data.monthlyCost}</h3>
                    <p className="label">עלות חודשית צפויה</p>
                </div>
            </div>
        </div>
    );
};

export default BudgetCard;
