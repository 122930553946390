import { ReactComponent as BdayIcon } from '../../assets/events/Bday.svg';
import { ReactComponent as BabyIcon } from '../../assets/events/baby.svg';
import { ReactComponent as GlassIcon } from '../../assets/events/glass.svg';
import { ReactComponent as WeddingIcon } from '../../assets/events/wadidng.svg';
export const calcDates = (timestamp: number) => {
    const eventDate = new Date(timestamp);
    const day = String(eventDate.getDate()).padStart(2, '0');
    const month = String(eventDate.getMonth() + 1).padStart(2, '0');
    const year = String(eventDate.getFullYear()).slice(-2);
    const formattedDate = `${day}.${month}.${year}`;

    const today = new Date();
    const timeDiff = eventDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    const monthsLeft = Math.floor(daysLeft / 30);
    const yearsLeft = Math.floor(monthsLeft / 12);

    let timeLeftMessage = '';
    if (yearsLeft > 0) {
        timeLeftMessage = `נותרו ${yearsLeft} שנה${yearsLeft > 1 ? 'ות' : ''}`;
    } else if (monthsLeft > 0) {
        timeLeftMessage = `נותרו ${monthsLeft} חודש${monthsLeft > 1 ? 'ים' : ''}`;
    } else if (daysLeft > 0) {
        timeLeftMessage = `נותרו ${daysLeft} ימ${daysLeft > 1 ? 'ים' : ''}`;
    } else {
        timeLeftMessage = 'האירוע חל היום!';
    }
    return { formattedDate, timeLeftMessage };
};

export const checkEventSvgByName = (eventType: string) => {
    if (eventType === 'birthday') return BdayIcon;
    else if (eventType === 'yearlyDayInCompany') return GlassIcon;
};
